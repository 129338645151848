@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

.container-m-p {
  @apply flex flex-col items-start pt-24 pb-24 text-left;
}

.inputBox {
  @apply placeholder:text-gray-400 block bg-white w-full border border-gray-300 rounded-sm mb-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm;
}

.labelContent {
  @apply after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-gray-700;
}
